import {
    SET_REBATE_DATA,
    ERROR_REBATE_DATA,
    RESET_REBATE_DATA,
} from '../actionTypes/RebatesActionTypes';

const defaultState = { data: [], error: false };

function RebatesReducer(state = defaultState, action) {
    const { payload } = action;
    switch (action.type) {
        case SET_REBATE_DATA:
            return { data: payload, error: false };
        case ERROR_REBATE_DATA:
            return { data: [], error: true };
        case RESET_REBATE_DATA:
            return defaultState;
        default:
            return state;
    }
}
RebatesReducer.reducerName = 'rebatesInfo';

export default RebatesReducer;

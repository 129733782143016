import { put, call, takeLatest, select } from 'redux-saga/effects';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getPriceByUrl } from 'yoda-interfaces/lib/Product/ProductPricingApi';
import productPricingActionTypes from '../actionTypes/ProductPricingActionTypes';
import { selectSizeBasedOnDeviceType, selectFeatureFlags } from '../selectors/ContextSelector';
import { isDeliveryOptionRequired } from '../selectors/ProductDetailsSelector';
import loadingActions from '../actions/LoadingAction';
import { X_CLIENT_SOURCE_PDP, X_CLIENT_SOURCE_MINI_PDP } from '../common/Constants';
import { getDisableBYDeliveryDateFlag } from '../selectors/byDeliveryDateSelector';

function* productPricingSaga(price) {
    const featureFlags = yield select(selectFeatureFlags);
    try {
        if (get(featureFlags, 'enableSKUAvailabilityLogic', true)) {
            const customLoadOption = { customLoader: true };
            yield put(loadingActions.showCustomLoader(customLoadOption));
        }
        const deviceSize = yield select(selectSizeBasedOnDeviceType);
        const sourceMiniPDP = price.payload.fromMiniPDP;
        const isDeliveryOption = yield select(isDeliveryOptionRequired(sourceMiniPDP));
        const searchParams = new URLSearchParams(window?.location?.search);
        const utmSourceParam = searchParams.get('utm_source');
        const gUTMSource = utmSourceParam === 'google';
        const { myAlertsNew, myAlertsMiniPDP, enableLocalization, virtualAPI } = featureFlags;
        const params =
            myAlertsNew || myAlertsMiniPDP
                ? {
                      ...price.payload.params,
                      deliveryAvailabilityCheckRequired: isDeliveryOption,
                      ...(enableLocalization && { GPA: gUTMSource }),
                  }
                : price.payload.params;
        const disableBYDeliveryDate = yield select(getDisableBYDeliveryDateFlag);
        let clientSourceHeader;
        if (!disableBYDeliveryDate) {
            clientSourceHeader = sourceMiniPDP ? X_CLIENT_SOURCE_MINI_PDP : X_CLIENT_SOURCE_PDP;
        }

        const pricing = yield call(
            getPriceByUrl,
            {
                url: price.payload.url,
                params,
                promiseAPI: price.payload.promiseAPI,
                deviceSize,
                xClientSource: clientSourceHeader,
            },
            virtualAPI
        );
        const modifiedPricing = {
            ...pricing,
        };

        const locationIds = get(
            modifiedPricing,
            'promise.skuDeliveryOptions[0].deliveryOptions.aggregatedBopus.locationIds[0]',
            {}
        );
        const requestStoreId = (params && params.stores) || '';
        const responseStoreId = get(locationIds, 'locationId', '');
        if (
            !isEmpty(requestStoreId) &&
            !isEmpty(responseStoreId) &&
            requestStoreId !== responseStoreId
        ) {
            const isSKUAvailable = get(locationIds, 'availabilityStatus', 'NA');
            if (isSKUAvailable !== 'NA') {
                modifiedPricing.promise.skuDeliveryOptions[0].deliveryOptions.aggregatedBopus.locationIds[0].skuAvailableInNearbyStore = true;
            }
        }

        if (get(featureFlags, 'enableSKUAvailabilityLogic', true)) {
            yield put(loadingActions.removeLoader());
        }
        yield put({
            type: productPricingActionTypes.PRODUCT_PRICING_GET_SUCCESS,
            pricing: modifiedPricing,
            sourceMiniPDP,
        });
    } catch (error) {
        if (get(featureFlags, 'enableSKUAvailabilityLogic', true)) {
            yield put(loadingActions.removeLoader());
        }
        yield put({
            type: productPricingActionTypes.PRODUCT_PRICING_GET_ERROR,
            error,
            sourceMiniPDP: price.payload.fromMiniPDP,
        });
    }
}

const watchProductPricingRequest = function* watchProductPricingRequest() {
    yield takeLatest(productPricingActionTypes.PRODUCT_PRICING_GET_REQUEST, productPricingSaga);
    yield takeLatest(
        productPricingActionTypes.PRODUCT_PRICING_WITH_CUSTOM_OPTIONS_GET_REQUEST,
        productPricingSaga
    );
    // yield takeLatest(productPricingActionTypes.PRODUCT_PRICING_FALLBACK_GET_REQUEST, productFallBackPricingSaga);
};

watchProductPricingRequest.sagaName = 'watchProductPricingRequest';

export default watchProductPricingRequest;
